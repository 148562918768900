html,
body {
  height: 100%;
}

html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
figure,
blockquote,
dl,
dd,
pre {
  margin: 0;
  padding: 0;
}

ul,
ol {
  margin: 0;
  padding: 0;
  list-style: none;
}

pre {
  white-space: pre;
}

input,
button,
textarea,
select {
  font: inherit;
  margin: 0;
}

input[type='button'],
input[type='submit'],
input[type='file'],
button,
a {
  cursor: pointer;
}
@import "styles/reset";
@import "styles/buttons";
@import "styles/form-field";
@import "styles/variables";
@import "styles/font";
@import "styles/titles";
@import "styles/theme";

body {
  background-color: var(--bg-body);
}

.fnol-media-container {
  max-width: 760px;
  padding: 30px;
  width: 100%;
}

calingo-fnol-autocomplete .mat-chip.mat-standard-chip {
  background-color: var(--chip-bg-color);
  color: var(--chip-text-color);
}

mat-dialog-container.mat-dialog-container {
  max-height: 100vh;
}

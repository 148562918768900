:root {
  --bg-header: #002a32;
  --bg-footer: #002a32;
  --bg-stepper: #002a32;
  --bg-body: #f1f6f7;

  --bg-input: #ffffff;
  --color-placeholder: #b9c5ca;
  --border-color-input: #f1f6f7;
  --border-color-focused: #00686e;

  --bg-button: #e1ff00;
  --bg-button-inactive: #e8edf0;

  --color-primary: #002a32;
  --color-secondary: #00686e;
  --color-error: #f23030;
  --color-choice-inactive: #b9c5ca;
  --bg-choice-active: #ffffff;
  --color-info-row-title: #606473;

  --color-phone-number: #ffffff;
  --color-language-choice: #ffffff;

  --color-stepper-text: #ffffff;
  --color-stepper-line: #ffffff;
  --color-stepper-line-progressed: #00686e;
  --color-stepper-icon: #00686e;
  --color-stepper-active: #e1ff00;

  --chip-text-color: #e8edf0;
  --chip-bg-color: #00686e;


  // Border radius
  // Buttons and inputs
  --br-form-field: 52px;
  --br-textarea: 16px;
  --br-file-drop: 16px;
  --br-stepper-circle: 50%;

    /* Logo sizing */
    --width-logo: 100px;
    --height-logo: 30px;
    --phone-icon-width: 32px;
}
